<template>
  <div class="flex justify-center flex-grow">
    <div class="mt-10 border w-9/12 mx-auto shadow rounded-lg p-6 mb-10">
      <h1 class="text-center text-3xl">Einstellungen</h1>
      <Profile :user-id="$store.getters['auth/user'].id"></Profile>
    </div>
  </div>
</template>

<script>
import Profile from '@/components/settings/Profile.vue';

export default {
  name: 'Settings',
  components: {
    Profile,
  },
  methods: {
  },
};
</script>

<style scoped>
input:checked + svg {
  display: block;
}
</style>
